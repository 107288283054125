/* About us banner */
.about-us-header {
  min-height: 70vh;
  background-image: linear-gradient(
      to right,
      #032d5f 10%,
      rgba(1, 17, 36, 0.5) 47%,
      rgba(0, 0, 0, 0)
    ),
    url(/public/images/about/about-first.jpg);
  /* background-position: 0 0, 100% -10%; */
  background-repeat: repeat, repeat;
  background-size: auto, cover;
  padding-top: 160px;
  padding-bottom: 110px;
  position: relative;
  /* z-index: -1; */
}

.about-us-header h1 {
  font-size: 2.8rem;
  margin-bottom: 10px;
  margin-left: 80px;
  border-bottom: 2px solid;
  display: inline;
}

.about-us-container .about-us-header p {
  font-size: 1.1rem;
  color: #fdfdfd;
  /* line-height: 4; */
  margin-left: 120px;
  border-left: 2px solid orange;
  margin-top: 6px;
  padding: 10px;
}

/* header-subnav */
.about-us-header .header-subnav {
  margin-left: 80px;
  font-size: 19px;
  margin-bottom: 10px;
}

.about-us-header .header-subnav a {
  text-decoration: none;
  color: aliceblue;
}

.about-us-header .header-subnav span {
  margin: 0px 3px;
}

/* about footer */
.about-us-container footer p {
  font-size: 1.3rem;
  color: #444;
  font-weight: bold;
  /* margin-top: 40px; */
  padding: 20px 0;
  background-color: #f4f7fc;
  /* border-radius: 8px; */
}

.about-us-footer {
  margin-left: 74px;
  /* border-left: 4px solid blue; */
  /* padding-top: 150px; */
  /* padding: 70px 0px; */
  margin-bottom: 80px;
}

/* Responsive css-medium */
@media screen and (max-width: 991px) {
  .about-us-container h2 {
    font-size: 48px !important;
  }
}

@media screen and (max-width: 550px) {
  .about-us-container h2 {
    font-size: 34px !important;
  }
  .about-us-container p {
    font-size: 17px !important;
  }
}

@media screen and (max-width: 400px) {
  .about-us-container h2 {
    font-size: 28px !important;
  }
  .about-us-container p {
    font-size: 16px !important;
  }
  .about-us-footer {
    margin-left: 50px;
    margin-bottom: 60px;
  }
}
