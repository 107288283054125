/* top-css */
.why-choose-us {
  padding: 40px 20px;
  border-radius: 30px;
  /* color: #333; */
  margin-bottom: 110px;
}

.why-us-top {
  margin-bottom: 25px;
}

.why-choose-us h2 {
  font-size: 54px;
  font-weight: 700;
  line-height: 1.222em;
}

.why-choose-us .why-us-top-subtitle {
  color: blue;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  margin-bottom: 8px;
  font-weight: 700;
  line-height: 1.111em;
  font-size: 20px;
}

.why-us-top .subtitle-tag {
  color: #007bff;
  /* text-decoration: underline; */
}

.why-choose-us .why-us-tag-button {
  display: flex;
  justify-content: center;
  /* align-items: center; */
}

.why-us-tag-button .drag-button {
  width: 100px;
  height: 100px;
  border-radius: 53px;
  font-size: 30px;
  background-color: azure;
  color: #333;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.why-us-tag-button .drag-button .drag-content {
  cursor: grab;
  color: black;
  background-color: rgb(0, 187, 255);
  font-weight: 600;
  /* padding: 10px;  */
  font-size: 22px;
  border-radius: 56px;
  height: 70px;
  width: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* feature-section-bottom */
.features-grid-container {
  grid-column-gap: 28px;
  grid-row-gap: 28px;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr 1fr;
  grid-auto-columns: 1fr;
  display: grid;
}

.why-choose-us .feature-card {
  padding: 60px 30px;
  background-color: #89bcff;
  border-radius: 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.why-us-inner-content .feature-heading {
  color: #080e24;
  font-size: 28px;
  font-weight: 700;
  line-height: 1.429em;
}

.feature-card p {
  color: #080e24;
}

.why-choose-us .feature1 {
  margin-top: 140px;
}

.why-choose-us .feature2 {
  margin-top: 70px;
  margin-bottom: 70px;
}

.why-choose-us .feature3 {
  margin-bottom: 140px;
}

.feature-card .feature-img {
  width: 100px;
  height: 100px;
  margin-bottom: 12px;
  justify-content: center;
  display: flex;
  align-items: center;
}

.feature-img img {
  width: 100%;
  height: 100%;
  border-radius: 60px;
}

.feature-img .globe-img {
  font-size: 70px;
}

/* Responsive css-medium */
@media screen and (max-width: 991px) {
  .why-choose-us {
    padding: 40px 0px;
  }

  .why-choose-us h2 {
    font-size: 48px;
  }

  .why-choose-us .why-us-tag-button {
    display: none;
  }
  .why-choose-us .why-us-top {
    display: block;
  }
  .why-us-top .why-us-top-content {
    width: 100%;
    text-align: center;
  }

  .why-choose-us .features-grid-container {
    grid-template-columns: 1fr;
    margin-top: 60px;
  }

  .features-grid-container .feature1 {
    margin-top: 0px;
  }
  .features-grid-container .feature2 {
    margin-top: 0px;
    margin-bottom: 0px;
  }
  .features-grid-container .feature3 {
    margin-bottom: 0px;
  }
}

@media screen and (max-width: 550px) {
  .why-choose-us h2 {
    font-size: 34px;
  }
  .why-choose-us .feature-card {
    padding: 30px 30px;
  }
  .feature-card .feature-img {
    width: 70px;
    height: 70px;
  }
  .feature-img .globe-img {
    font-size: 60px;
  }
}
