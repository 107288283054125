/* contact-form-section */

/* form-top-content */
.contact-form {
  margin-top: 20px;
}

.contact-form-top {
  margin-bottom: 25px;
}

.contact-form-top-content h2 {
  font-size: 54px;
  font-weight: 700;
  line-height: 1.222em;
}

.contact-form-top-content .contact-form-top-subtitle {
  color: blue;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  margin-bottom: 8px;
  font-weight: 700;
  line-height: 1.111em;
  font-size: 20px;
}

.contact-form-top .subtitle-tag {
  color: #007bff;
  text-decoration: underline;
}

.contact-form-top .contact-form-tag-button {
  display: flex;
  justify-content: center;
  /* align-items: center; */
}

.contact-form-tag-button .drag-button {
  width: 100px;
  height: 100px;
  border-radius: 53px;
  font-size: 23px;
  background-color: azure;
  color: #333;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.contact-form-tag-button .drag-button .drag-content {
  cursor: grab;
  color: black;
  background-color: rgb(0, 187, 255);
  font-weight: 600;
  /* padding: 10px;  */
  font-size: 19px;
  border-radius: 56px;
  height: 75px;
  width: 75px;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* form-Bottom-content */

/* left-form-bottom-content */
.contact-us-section .contact-form-grid {
  margin: 70px 0px;
  grid-template-rows: auto;
  grid-auto-columns: 1fr;
  padding: 0px 25px;
  display: grid;
  grid-column-gap: 20px;
  grid-row-gap: 60px;
  /* grid-template-columns: 1.5fr 1fr; */
  grid-template-columns: 1.5fr minmax(377px, 1fr);
  position: relative;
}

.contact-form-wrapper {
  background-color: #fafafa;
  border: 1px solid #f3f6ff;
  box-shadow: none;
  border-radius: 24px;
  align-items: center;
  padding: 80px 50px;
  overflow: hidden;
  /* margin-left: 50px; */
  margin-top: 80px;
}

.contact-form-card {
  min-height: 594px;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  margin-bottom: 0;
  display: flex;
}

.contact-form-card .inputs-grid {
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  align-items: center;
  display: grid;
  grid-column-gap: 32px;
  grid-row-gap: 32px;
  margin-bottom: 35px;
}

.contact-form-card label {
  font-size: 20px;
  color: #080e24;
  margin-bottom: 12px;
  font-weight: 700;
  display: block;
}

.contact-form-card .servicetype {
  font-size: 20px;
  color: #080e24;
  margin-bottom: 12px;
  font-weight: 700;
  display: block;
}

.contact-form-card .field-error {
  color: red;
}

.inputs-grid input {
  min-height: 72px;
  border: 1px solid #f3f6ff;
  background-color: #f3f6ff;
  color: #080e24;
  border-radius: 14px;
  margin-bottom: 0;
  padding: 16px 24px;
  font-size: 18px;
  line-height: 1.111em;
  width: 100%;
  height: 38px;
  display: block;
}

.contact-form-card textarea {
  width: 100%;
  height: 150px;
  padding: 20px;
  border-radius: 14px;
  /* margin-bottom: 32px; */
  border: 1px solid #f3f6ff;
  background-color: #f3f6ff;
}

.contact-form-card .form-submit-button {
  padding: 16px 36px;
  background-color: #0dcaf0;
  box-shadow: 7px 7px 0 0 #000;
  transition: all 200ms ease;
  text-decoration: none;
  color: #0e0f18;
  font-size: 18px;
  line-height: 150%;
  font-weight: 500;
  border-radius: 10px;
  border: none;
}
.form-submit-button:hover {
  box-shadow: none;
  background-color: #007bff;
  color: #000;
}

/* right-form-content */
.contact-form-content {
  /* max-width: 508px; */
  padding-top: 60px;
  color: #333;
}

.contact-form-content-bg {
  background-color: #97cafe;
  border-top-left-radius: 24px;
  border-bottom-left-radius: 24px;
  position: absolute;
  top: 0%;
  /* bottom: 15%; */
  min-height: 850px;
  left: 45%;
  right: 0%;
  z-index: -1;
}

/* .form-inner-content {
  padding-right: 50px;
} */

.contact-form-content .form-content-card {
  display: flex;
  column-gap: 15px;
  border-bottom: 1px solid;
  padding-bottom: 40px;
  padding-top: 40px;
}

.contact-form-content .address-card {
  border-bottom: none;
}

.form-content-card h2 {
  color: #0056b3;
  font-size: 28px;
  font-weight: 700;
  line-height: 1.429em;
  margin-bottom: 8px;
}

.form-content-card p {
  margin-bottom: 24px;
  font-size: 18px;
  padding-right: 15px;
}

.form-content-card .contact-address {
  border: 2px solid;
  /* text-align: center; */
  text-align: start;
  padding: 15px;
  border-radius: 20px;
}

.form-content-card .btn-outline-primary {
  padding: 14px;
  font-weight: 600;
}

.contact-card-icon {
  font-size: 40px;
  background-color: aqua;
  min-width: 60px;
  height: 60px;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.contact-form-button {
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.contact-form-button .contact-response {
  color: green;
  margin-top: 25px;
  padding: 18px;
  text-align: center;
  border: 2px solid;
  border-radius: 20px;
  font-size: 18px;
}

.contact-form-button .contact-error {
  color: red;
  margin-top: 25px;
  padding: 18px;
  text-align: center;
  border: 2px solid;
  border-radius: 20px;
  font-size: 18px;
}

@media screen and (max-width: 1060px) {
  .contact-form-content-bg {
    /* bottom: 10%; */
    min-height: 900px;
  }
}

@media screen and (max-width: 991px) {
  .contact-form-top-content h2 {
    font-size: 48px;
  }
  .contact-us-section .contact-form-tag-button {
    display: none;
  }
  .contact-form-top .contact-form-top-content {
    width: 100%;
  }

  .contact-us-section .contact-form-grid {
    grid-row-gap: 0px;
    grid-template-columns: 1fr;
  }
  .contact-form-wrapper {
    order: 9999;
    margin-top: 0px;
  }

  .contact-form-content-bg {
    bottom: 25%;
    left: 0%;
  }
}

@media screen and (max-width: 720px) {
  .contact-form-card .inputs-grid {
    grid-template-columns: 1fr;
  }
}

@media screen and (max-width: 550px) {
  .contact-form-top-content h2 {
    font-size: 34px;
  }

  .form-content-card h2 {
    font-size: 24px;
  }

  .form-content-card p {
    font-size: 17px;
  }

  .contact-form-wrapper {
    padding: 60px 30px;
  }

  .inputs-grid input {
    min-height: 60px;
    border-radius: 10px;
    font-size: 16px;
  }
}

@media screen and (max-width: 400px) {
  .contact-form-top-content h2 {
    font-size: 28px;
  }

  .form-content-card h2 {
    font-size: 20px;
  }

  .form-content-card p {
    font-size: 14px;
  }

  .contact-us-section .contact-form-grid {
    margin: 40px 0px;
    padding: 0px 14px;
  }
  .contact-form-content {
    padding-top: 30px;
  }
  .contact-card-icon {
    font-size: 25px;
    background-color: aqua;
    min-width: 40px;
    height: 40px;
  }
  .contact-form-content .form-content-card {
    column-gap: 10px;
  }

  .form-content-card .btn-outline-primary {
    padding: 10px;
    font-size: 14px;
  }

  .contact-form-wrapper {
    padding: 50px 20px;
  }
  .contact-form-card label {
    font-size: 17px;
  }
  .contact-form-card .inputs-grid {
    grid-column-gap: 12px;
    grid-row-gap: 15px;
    margin-bottom: 15px;
  }
  .inputs-grid input {
    min-height: 50px;
    padding: 10px 18px;
  }
  .contact-form-card .form-submit-button {
    padding: 10px 20px;
    box-shadow: 5px 5px 0 0 #000;
    font-size: 16px;
  }
}
