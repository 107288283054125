.get-a-quote-section {
  /* min-height: 100vh; */
  margin: 60px 0px;
}

.quote-inner-content {
  background-color: #97cafe;
  color: #333;
  margin-bottom: 25px;
  padding: 40px;
  border-radius: 20px;
  padding-bottom: 0px;
  padding-right: 0px;
  padding-top: 80px;
}

.quote-inner-content h2 {
  font-size: 54px;
  font-weight: 700;
  line-height: 1.222em;
  margin-bottom: 35px;
}

.quote-inner-content p {
  font-size: 19px;
  margin-bottom: 40px;
}

.quote-inner-content .quote-subtitle {
  color: blue;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  margin-bottom: 8px;
  font-weight: 700;
  line-height: 1.111em;
  font-size: 20px;
}

.quote-inner-content .subtitle-tag {
  color: #007bff;
  /* text-decoration: underline; */
}

.quote-img{
  width: 200px;
  height: 200px;
  margin: auto;
}

.quote-img img {
  width: 100%;
  height: 100%;
  border-radius: 20px 0px 18px 0px;
}

.quote-container {
  display: flex;
  flex-direction: column;
}

.quote-buttons {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  margin-bottom: 30px;
}

.quote-buttons .quote-or {
  font-size: 35px;
}

.quote-contact-button {
  padding: 16px 36px;
  border: 1px solid #000;
  box-shadow: 7px 7px 0 0 #000;
  transition: all 200ms ease;
  text-decoration: none;
  color: #0e0f18;
  font-size: 18px;
  line-height: 150%;
  font-weight: 500;
}

.quote-contact-button:hover {
  box-shadow: none;
}

@media screen and (max-width: 991px) {
  .quote-inner-content h2 {
    font-size: 48px;
  }
  .quote-inner-content p {
    font-size: 18px;
  }

  .quote-buttons {
    margin-bottom: 30px;
  }
  .quote-buttons .home-button {
    padding: 10px 15px;
    font-size: 16px;
  }
  .quote-contact-button {
    padding: 10px 15px;
    font-size: 16px;
  }
  .quote-buttons .quote-or {
    font-size: 30px;
}
}

@media screen and (max-width: 550px) {
  .quote-inner-content h2 {
    font-size: 34px;
  }
  .quote-inner-content p {
    font-size: 17px;
  }
}

@media screen and (max-width: 400px) {
  .quote-inner-content h2 {
    font-size: 28px;
    margin-bottom: 25px;
  }
  .quote-inner-content p {
    font-size: 16px;
    margin-bottom: 30px;
  }

  .quote-inner-content {
    background-color: #97cafe;
    color: #333;
    margin-bottom: 25px;
    padding: 10px;
    border-radius: 20px;
    padding-bottom: 0px;
    padding-right: 0px;
    padding-top: 45px;
}

.quote-buttons{
  flex-direction: column;
    align-items: center;
    justify-content: center;
}
}