.footer {
  color: #f0f0f0 !important;
  padding-top: 60px;
  position: relative;
  font-family: "Arial", sans-serif;
  font-size: 0.95rem;
  margin-top: 15px;
  background-color: #1e2a38 !important;
  /* background: linear-gradient(rgba(6, 3, 21, .5), rgba(6, 3, 21, .5)), url(/public/images/logistics-bg.webp) center center no-repeat; */
  /* background-size: cover; */
}

/* .footer .container {
  display: flex;
  flex-direction: column;
  align-items: center;
} */

.footer-content {
  display: grid;
  grid-template-columns: 1.5fr 0.5fr 1.5fr 1fr;
}

.footer-section {
  /* flex: 1 1 200px; */
  margin: 20px 0;
  min-width: 180px;
}

.footer-content .logo-quote {
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-right: 50px;
  text-align: center;
}

.company-logo {
  max-width: 220px;
  padding-bottom: 18px;
  border-radius: 10px;
}

/* .company-logo:hover {
  transform: scale(1.05);
} */

.footer-quote {
  font-size: 1.1rem;
  font-style: italic;
  color: #fff;
  margin-top: 20px;
}

.footer h5 {
  font-size: 1.2rem;
  margin-bottom: 20px;
  color: #ffffff;
  position: relative;
  padding-bottom: 10px;
  display: inline-block;
}

.footer h5::after {
  content: "";
  position: absolute;
  width: 50px;
  height: 2px;
  background-color: #007bff;
  bottom: 0;
  left: 0;
}

.footer ul {
  list-style: none;
  padding: 0;
}

.footer ul li {
  margin-bottom: 10px;
}

.footer ul li a {
  color: #fff;
  text-decoration: none;
  transition: color 0.3s ease;
}

.footer ul li a:hover {
  color: #007bff;
  /* scale: 1.1; */
  transform: scale(2.1);
  /* font-size: 18px; */
}

.footer .contact-info ul li {
  display: flex;
  align-items: center;
}

.footer .contact-info li svg {
  margin-right: 10px;
  color: #007bff;
}

.footer .social-media a {
  color: #f0f0f0;
  margin: 0 8px;
  font-size: 1.2rem;
  transition: color 0.3s ease, transform 0.3s ease;
}

.footer .social-media a:hover {
  color: #007bff;
  transform: scale(1.1);
}

/* .back-to-top {
  margin-top: 30px;
} */

.back-to-top a {
  display: inline-flex;
  align-items: center;
  font-size: 1rem;
  color: #007bff;
  text-decoration: none;
  font-weight: bold;
  transition: color 0.3s ease, transform 0.3s ease;
}

.back-to-top a:hover {
  color: #f0f0f0;
  transform: translateY(-3px);
}

.back-to-top a svg {
  margin-right: 8px;
}

.footer-bottom {
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  width: 100%;
  padding: 20px 0;
  text-align: center;
  /* margin-top: 30px; */
}

.footer-bottom p {
  margin: 0;
  font-size: 0.85rem;
  opacity: 0.8;
}

/* Responsive css-medium */
@media screen and (max-width: 991px) {
  .footer-content {
    height: auto;
    /* grid-row-gap: 60px; */
    grid-template-columns: 1.5fr 1fr;
    grid-column-gap: 60px;
  }
  .footer-section {
    max-width: 100%;
  }

  .footer-content .logo-quote {
    padding-right: 0px;
  }
}

@media screen and (max-width: 470px) {
  .footer-content {
    height: auto;
    grid-row-gap: 20px;
    grid-template-columns: 1fr;
    /* grid-column-gap: 60px; */
  }
  .footer-section {
    margin: 0;
  }
}

@media screen and (max-width: 270px) {
  .footer ul li .company-mail {
    font-size: 12px;
  }
  .footer .company-logo {
    max-width: 180px;
    padding-bottom: 0px;
    border-radius: 10px;
}
}
