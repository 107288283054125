.our-services-preview {
  padding: 110px 0px;
  padding-bottom: 70px;
  background-color: #89bcff;
  color: #333;
  margin-top: 130px;
  margin-bottom: 150px;
}

.services-top {
  margin-bottom: 25px;
}

.our-services-preview .service-content {
  padding-left: 30px;
}

.our-services-preview .services-subtitle {
  color: blue;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  margin-bottom: 8px;
  font-weight: 700;
  line-height: 1.111em;
  font-size: 20px;
}

.services-top .subtitle-tag {
  /* color: #007bff; */
  color: #0056b3;;
  /* text-decoration: underline; */
}

.our-services-preview h2 {
  font-size: 54px;
  font-weight: 700;
  line-height: 1.222em;
}

.services-top .service-tag-button {
  display: flex;
  justify-content: center;
  /* align-items: center; */
}

.service-tag-button .drag-button {
  width: 100px;
  height: 100px;
  border-radius: 53px;
  font-size: 30px;
  background-color: #000;
  color: azure;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.service-tag-button .drag-button .drag-content {
  cursor: grab;
  color: black;
  background-color: rgb(0, 187, 255);
  font-weight: 600;
  /* padding: 10px;  */
  font-size: 25px;
  border-radius: 56px;
  height: 70px;
  width: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
}


.service-cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  gap: 30px;
}

.service-card {
  width: 20rem;
  background: white;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

/* .service-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
} */

.service-card-image {
  width: 100%;
  height: 250px;
  overflow: hidden;
}

.service-card-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.service-card:hover .service-card-image img {
  transform: scale(1.1);
}

.service-card:hover .service-card-content h3 {
  color: #007bff;
}

.service-card-content {
  padding: 18px;
}

.service-card-content h3 {
  /* font-family: "Raleway", sans-serif; */
  font-weight: 700;
  /* font-size: 1.5rem; */
  font-size: 20px;
  color: #333;
  margin-bottom: 5px;
}

.service-card-content h4 {
  font-family: "Raleway", sans-serif;
  font-weight: 500;
  font-size: 1rem;
  margin-bottom: 15px;
}

.service-card-content p {
  font-family: "Raleway", sans-serif;
  font-size: 1rem;
  margin-bottom: 0;
  font-style: italic;
}

/* service card button */
.service-card-button {
  padding: 10px 20px;
  border: 1px solid #000;
  background-color: #fff;
  box-shadow: 7px 7px 0 0 #97cafe;
  transition: all 200ms ease;
  text-decoration: none;
  color: #0e0f18;
  font-size: 16px;
  line-height: 150%;
  font-weight: 500;
  margin-bottom: 30px;
}

.service-card-button:hover {
  box-shadow: none;
}

.our-service-buttons {
  display: flex;
  flex-wrap: wrap;
  margin-top: 60px;
  justify-content: space-evenly;
}

.our-service-buttons .service-explore-button {
  padding: 16px 36px;
  border: 1px solid #495057;
  background-color: #fff;
  box-shadow: 7px 7px 0 0 #007bff;
  transition: all 200ms ease;
  text-decoration: none;
  color: #0e0f18;
  font-size: 18px;
  line-height: 150%;
  font-weight: 500;
  border-radius: 10px 0px 0px 0px;
}

.our-service-buttons .service-explore-button:hover {
  box-shadow: none;
  background-color: #495057;
  color: azure;
}

/* Responsive css-medium */
@media screen and (max-width: 991px) {
  .our-services-preview h2 {
    font-size: 48px;
  }

  .our-services-preview .services-top .service-tag-button {
    display: none;
  }

  .our-services-preview .services-top {
    display: block;
  }

  .services-top .service-content {
    width: 100%;
    text-align: center;
  }
}

/* Responsive css-small */
@media screen and (max-width: 550px) {
  .our-services-preview h2 {
    font-size: 34px;
  }

  .our-service-buttons a {
    margin-right: 20px;
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 400px) {
  .our-services-preview {
    padding: 60px 0px !important;
    margin-bottom: 100px;
  }
  .our-services-preview h2 {
    font-size: 28px;
  }

  .service-card-image {
    height: 180px;
  }
  .service-card-content {
    padding: 12px;
  }
  .service-card-content h3 {
    font-size: 1.2rem;
  }
  .service-card-content p {
    font-size: 0.8rem;
  }

  .our-service-buttons {
    margin-top: 36px;
  }
  .our-service-buttons a {
    padding: 10px 20px !important;
  }
}
