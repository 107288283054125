/* ==== Page not found page css === */

.container1 {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  justify-content: center;
  align-items: center;
}
.pnf2 {
  font-size: 48px;
  /* color: #333; */
}
.pnf1 {
  font-size: 18px;
  /* color: #666; */
}
.link1 {
  color: #0202fa;
  text-decoration: none;
}
.link1:hover {
  color: rgb(29, 181, 12);
  transition: 2s;
  text-decoration: underline;
}
