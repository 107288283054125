.aboutus-preview-section {
  min-height: 100vh;
  /* margin-bottom: 200px; */
  padding-bottom: 120px;
  padding-left: 40px;
  padding-top: 80px;
  color: #333;
  border-radius: 0px 0px 0px 30px;
}

.about-preview-grid-layout {
  display: grid;
  grid-column-gap: 40px;
  grid-template-columns: 1fr 1fr;
  align-items: start;
  grid-row-gap: 40px;
  grid-template-rows: auto;
}

/* right */
.preview-content {
  /* margin: 100px 0px; */
  padding-top: 130px;
  position: relative;
}

.inner-content {
  padding-right: 40px;
}

.preview-subtitle {
  color: blue;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  margin-bottom: 8px;
  font-weight: 700;
  line-height: 1.111em;
  font-size: 20px;
}

.preview-tag {
  color: #0056b3;
  /* text-decoration: underline; */
  /* font-family: "Yeseva One", serif !important; */
}

.preview-content-bg {
  background-color: #97cafe;
  border-top-left-radius: 24px;
  border-bottom-left-radius: 24px;
  margin-bottom: -80px;
  margin-left: -271px;
  position: absolute;
  top: 0%;
  bottom: 0%;
  left: 0%;
  right: 0%;
  z-index: -1;
}

.preview-content .preview-heading {
  font-size: 54px;
  font-weight: 700;
  line-height: 1.222em;
  margin-bottom: 30px;
}

.preview-heading .preview-quotes {
  color: #0056b3;
  text-decoration: underline;
}

.preview-content .preview-para {
  font-size: 18px;
  margin-bottom: 20px;
}

/* left */
.aboutus-preview-section .preview-img {
  max-height: 700px;
  /* height: 110%; */
  z-index: 1;
  /* max-width: 625px; */
  /* padding-top: 94px; */
  position: relative;
  margin-top: 80px;
}

.preview-img img {
  width: 100%;
  /* max-height: 100%; */
  height: 650px;
  border-radius: 20px;
  vertical-align: middle;
  /* position: relative;
  top: 50px; */
  object-fit: cover;
}

/* home-button */
.preview-buttons {
  display: flex;
  /* justify-content: stretch; */
  margin-top: 35px;
  margin-left: 30px;
}

/* Responsive css-medium */
@media screen and (max-width: 991px) {
  .aboutus-preview-section {
    padding-bottom: 0px;
  }

  .preview-content .preview-heading {
    font-size: 48px;
  }

  .preview-content .preview-para {
    font-size: 17px;
  }

  .about-preview-grid-layout {
    height: auto;
    grid-row-gap: 60px;
    grid-template-columns: 1fr;
  }

  .aboutus-preview-section .preview-img {
    order: 9999;
    max-width: 90%;
    max-height: 85%;
    margin-top: 0px;
  }
  .preview-img img {
    object-fit: fill;
  }

  .about-preview-grid-layout .preview-content-bg {
    margin-bottom: -400px;
  }
}

/* Responsive css-small */
@media screen and (max-width: 550px) {
  .aboutus-preview-section {
    padding-top: 10px;
  }

  .aboutus-preview-section .preview-content {
    padding-top: 80px;
  }

  .preview-content .preview-heading {
    font-size: 34px;
  }
  .preview-content .preview-para {
    font-size: 17px;
  }

  .aboutus-preview-section .preview-img img {
    height: 450px;
  }

  .about-preview-grid-layout .preview-content-bg {
    margin-bottom: -270px;
  }
}

@media screen and (max-width: 400px) {
  .about-preview-grid-layout {
    grid-row-gap: 40px;
  }

  .aboutus-preview-section {
    padding-left: 15px;
  }

  .aboutus-preview-section .preview-img img {
    height: 300px;
  }

  .about-preview-grid-layout .preview-content-bg {
    margin-bottom: -200px;
  }

  .preview-content .preview-heading {
    font-size: 28px;
  }

  .preview-content .preview-para {
    font-size: 16px;
  }
  .preview-buttons {
    margin-top: 0px;
  }
  .aboutus-preview-section .home-button {
    padding: 10px 20px;
    font-size: 16px;
  }
}
