.hero-section {
  position: relative;
}

.hero-section .carousel-item {
  position: relative;
  overflow: hidden;
}

.hero-section .subtitle-wrapper {
  background-color: rgba(4, 28, 55, 0.5);
  padding: 5px 10px 5px 10px;
  border-left: 6px solid orange;
  display: inline-block;
  position: relative;
}

.hero-section .carousel-item::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    to right,
    #032d5f 10%,
    rgba(1, 17, 36, 0.5) 35%,
    rgba(0, 0, 0, 0)
  );
  z-index: 1;
}

.hero-section img.d-block.w-100 {
  object-fit: cover;
  height: 100vh;
  position: relative;
  z-index: 0;
}

.hero-section .content-wrapper {
  position: absolute;
  top: 48%;
  left: 5%;
  transform: translateY(-50%);
  color: #fff;
  /* max-width: 40%; */
  max-width: 600px;
  z-index: 2; /* Ensures content is above the gradient */
}

.hero-section .subtitle-wrapper p {
  margin-bottom: 0px;
  font-size: 16px;
}

.hero-section h1 {
  font-style: normal;
  color: #fff;
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 54px;
  line-height: 1.2;
  /* text-decoration: underline orange; */
}

.hero-section p {
  font-style: normal;
  margin-bottom: 25px;
  font-size: 17px;
  font-weight: 500;
  line-height: 1.5;
}

.hero-section .carousel-pagination {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  gap: 10px;
  z-index: 2;
}

.hero-section .carousel-dot {
  width: 20px;
  height: 20px;
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.3s;
}

.hero-section .carousel-dot.active {
  background-color: rgba(255, 255, 255, 1);
}

.hero-section .home-banner-button {
  padding: 16px 0px;
  display: inline-block;
  overflow: hidden;
}

.hero-section .explore-button {
  font-size: 17px;
  text-decoration: none;
  color: #080e24;
  padding: 16px 35px;
  margin-top: 37px;
  background-color: #ffa500;
  position: relative;
  z-index: 1;
  overflow: hidden;
}

.hero-section .explore-button span {
  position: relative;
  z-index: 2;
  font-weight: bold;
}

.hero-section .explore-button .sub-button {
  width: 15px;
  top: 0;
  left: 0;
  bottom: 0px;
  background-color: #97cafe;
  position: absolute;
}

@media (max-width: 768px) {
  .hero-section .content-wrapper {
    max-width: 90%;
  }

  .hero-section .carousel-dot {
    width: 10px;
    height: 10px;
  }
}

@media screen and (max-width: 991px) {
  .hero-section h1 {
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 48px;
  }
}

@media screen and (max-width: 600px) {
  .hero-section .content-wrapper {
    max-width: 500px;
  }

  .hero-section h1 {
    font-size: 34px;
  }
  .hero-section p {
    font-size: 16px;
  }
}

@media screen and (max-width: 400px) {
  .hero-section .content-wrapper {
    max-width: 300px;
  }
  .hero-section h1 {
    font-size: 28px;
  }
  .hero-section p {
    font-size: 15px;
    margin-bottom: 15px;
  }
  .hero-section .explore-button {
    font-size: 15px;
    text-decoration: none;
    color: #080e24;
    padding: 10px 20px;
  }
  .hero-section .subtitle-wrapper p {
    font-size: 15px;
    margin-bottom: 0px;
  }
  .hero-section .explore-button .sub-button {
    width: 10px;
  }
}
