/* @import url('https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.1.1/animate.min.css'); */

.achievements {
  /* background: linear-gradient(135deg, #e0f7fa, #f1f8e9); */
  background: url(/public/images/about/about-bg.jpg) no-repeat center fixed;
  background-size: cover;
  position: relative;
  z-index: 0;
  padding: 50px 0px;
  margin: 60px 0px;
}

/* .achievements::before{ */
.achievements::after {
  content: "";
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  z-index: -1;
  background: rgb(0 0 0 / 45%);
}

.achievements h2 {
  font-size: 2.5rem;
  color: #97cafe;
  font-weight: bold;
  text-decoration: underline #007bff;
  margin-bottom: 70px;
}

.achievement-card {
  background-color: #fff;
  border-radius: 15px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  min-height: 260px;
  padding: 30px;
  position: relative;
  overflow: hidden;
  opacity: 1; /* Ensures the card is visible when animated */
}

.opacity-0 {
  opacity: 0; /* Initially hide the cards */
}

.achievement-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.15);
}

.icon-container {
  background-color: #007bff;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}

.icon {
  color: #fff;
  font-size: 2rem;
}

.achievement-title {
  color: #007bff;
  font-size: 2.5rem;
  font-weight: bold;
  margin-top: 20px;
}

.achievement-subtitle {
  color: #555;
  font-weight: 600;
  margin-top: 10px;
  font-size: 1.2rem;
}

.achievement-description {
  font-size: 1rem;
  color: #666;
  margin-top: 10px;
}

@media (max-width: 400px) {
  .achievements h2 {
    font-size: 2rem;
    color: #97cafe;
    font-weight: bold;
    -webkit-text-decoration: underline #007bff;
    text-decoration: underline #007bff;
    margin-bottom: 40px;
  }
  .achievement-title {
    font-size: 2rem;
    margin-top: 10px;
  }
}

@media (max-width: 250px) {
  .achievements h2 {
    font-size: 25px;
  }
}