.home-button {
  padding: 16px 36px;
  border: 1px solid #000;
  background-color: #fff;
  box-shadow: 7px 7px 0 0 #000;
  transition: all 200ms ease;
  text-decoration: none;
  color: #0e0f18;
  font-size: 18px;
  line-height: 150%;
  font-weight: 500;
  /* margin-right: 20px; */
  border-radius: 10px 0px 0px 10px;
}

.home-button:hover {
  box-shadow: none;
}

@media screen and (max-width: 400px) {
  .home-button{
    padding: 10px 20px !important;
    font-size: 16px;
  }
}
