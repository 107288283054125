.single-service-section {
  min-height: 100vh;
}

/* single service header banner */
.single-services-header {
  min-height: 70vh;
  background-image: linear-gradient(
      to right,
      #032d5f 10%,
      rgba(1, 17, 36, 0.5) 47%,
      rgba(0, 0, 0, 0)
    ),
    url(/public/images/about/about-first.jpg);
  /* background-position: 0 0, 100% -10%; */
  background-repeat: repeat, repeat;
  background-size: auto, cover;
  padding-top: 160px;
  padding-bottom: 110px;
  position: relative;
  /* z-index: -1; */
  /* because of z-index:-1 subnav notworking */
}

.single-services-header h1 {
  font-size: 2.8rem;
  margin-bottom: 0px;
  margin-left: 80px;
  border-bottom: 2px solid;
  display: inline;
}

.single-services-header p {
  font-size: 1.1rem;
  color: #fdfdfd;
  /* line-height: 4; */
  margin-left: 120px;
  border-left: 2px solid orange;
  margin-top: 6px;
  padding: 10px;
}

/* header-subnav */
.single-services-header .header-subnav {
  margin-left: 80px;
  font-size: 19px;
  margin-bottom: 10px;
}

.single-services-header .header-subnav a {
  text-decoration: none;
  color: aliceblue;
}

.single-services-header .header-subnav span {
  margin: 0px 3px;
}

/* Hero-Section- top-css */
.single-services-top {
  padding-top: 150px;
  padding-bottom: 80px;
}

.single-services-top-bg {
  /* background-color: #97cafe; */
  color: #333;
  /* border-radius: 0px 0px 20px 20px; */
  /* margin-bottom: 100px; */
}

.single-service-hero-bg {
  width: 100%;
  min-height: 800px;
  background-color: #97cafe;
  border-bottom-right-radius: 24px;
  border-bottom-left-radius: 24px;
  top: 70%;
  bottom: 0%;
  left: auto;
  right: auto;
  transform: translate(0);
  position: absolute;
  z-index: -1;
}

.single-service-content {
  max-width: 800px;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.single-service-content img {
  width: 100px;
  height: 100px;
  border-radius: 65px;
  margin-bottom: 20px;
}

.single-service-content .single-services-subtitle {
  /* color: blue; */
  color: #0056b3;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  margin-bottom: 8px;
  font-weight: 700;
  line-height: 1.111em;
  font-size: 20px;
}

.single-service-content .single-services-title {
  font-size: 60px;
  font-weight: 700;
  line-height: 1.111em;
  /* width: 800px; */
  margin-bottom: 30px;
  color: #0056b3;
  /* text-decoration: underline blue; */
}

.single-services-title .subtitle-tag {
  color: #007bff;
  text-decoration: underline;
}

.single-service-content p {
  /* width: 850px; */
  font-size: 18px;
}

/* service-content-section */
/* .single-service {
  position: relative;
  z-index: 3;
  top: -130px;
} */

.service-single-grid {
  grid-row-gap: 28px;
  grid-template-rows: auto;
  grid-auto-columns: 1fr;
  /* align-items: center; */
  display: grid;
  z-index: 1;
  grid-column-gap: 26px;
  grid-template-columns: 1fr 0.63fr;
  align-items: start;
  position: relative;
  /* border: 2px solid; */
}

.single-service-top-img {
  height: 600px;
  border-radius: 24px;
  align-items: center;
  margin-bottom: 92px;
  display: flex;
  overflow: hidden;
}

.single-service-top-img img {
  width: 100%;
  height: 100%;
}

.service-body h2 {
  /* color: #080e24; */
  margin-top: 0;
  font-size: 38px;
  font-weight: 700;
  line-height: 1.316em;
  margin-bottom: 24px;
}

.service-body .subtitle {
  color: blue;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  margin-bottom: 8px;
  font-weight: 700;
  line-height: 1.111em;
  font-size: 17px;
}

.service-body p {
  margin-top: 16px;
  margin-bottom: 16px;
  font-weight: 400;
}

.service-body .service-para3 {
  margin-bottom: 40px;
}

.service-body ol {
  margin-top: 32px;
  margin-bottom: 48px;
}

.service-body ol li {
  margin-bottom: 8px;
  padding-left: 8px;
}

.single-service-bottom-img {
  height: 400px;
  border-radius: 24px;
  align-items: center;
  display: flex;
  overflow: hidden;
}

.single-service-bottom-img img {
  width: 100%;
  height: 100%;
}

/* grid-right-content-card */

.single-service-quote {
  width: 100%;
  border: 2px solid;
  /* padding: 50px 40px;
  position: sticky;
  top: 20px; */
  padding: 35px 40px;
  position: sticky;
  top: 85px;
  border-radius: 20px;
  background-color: #0dcaf0;
  color: #333;
}

.single-service-quote .service-contact-icon {
  font-size: 40px;
  display: inline-block;
  width: 70px;
  height: 70px;
  background-color: #0e0f18;
  color: beige;
  text-align: center;
  border-radius: 40px;
  margin-left: 10px;
  margin-bottom: 15px;
}

.single-service-quote h2 {
  color: #0056b3;
  font-size: 34px;
  font-weight: 700;
  line-height: 1.294em;
}

.single-service-quote p {
  margin-bottom: 20px;
  font-size: 18px;
}

/* single-service-quote-buttons */
.single-quote-buttons {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.single-quote-buttons .single-quote-or {
  font-size: 35px;
}

.single-quote-contact-button {
  padding: 16px 36px;
  border: 1px solid #000;
  box-shadow: 7px 7px 0 0 #000;
  transition: all 200ms ease;
  text-decoration: none;
  color: #0e0f18;
  font-size: 18px;
  line-height: 150%;
  font-weight: 500;
}

.single-quote-contact-button:hover {
  box-shadow: none;
}

@media screen and (max-width: 991px) {
  .single-service-content .single-services-title {
    font-size: 50px;
  }
  .service-single-grid {
    grid-row-gap: 60px;
    grid-template-columns: 1fr;
  }

  .single-service-top-img {
    height: 500px;
    margin-bottom: 80px;
  }
  .service-body h2 {
    font-size: 32px;
  }
  .single-service-bottom-img {
    height: 350px;
  }

  .single-quote-buttons {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    justify-content: space-evenly;
  }

  .single-service-hero-bg {
    min-height: 900px;
    border-radius: 0px 0px 20px 0px;
  }
  .single-services-top {
    padding-top: 130px;
    padding-bottom: 60px;
  }
}

@media screen and (max-width: 550px) {
  .single-service-content .single-services-title {
    font-size: 40px;
  }

  .single-services-header p {
    margin-top: 0px;
  }

  .single-service-content p {
    font-size: 17px;
  }

  .single-service-content img {
    width: 80px;
    height: 80px;
  }

  .single-service-top-img {
    height: 400px;
    margin-bottom: 70px;
  }
  .service-body h2 {
    font-size: 30px;
  }
  .single-service-bottom-img {
    height: 300px;
  }

  .single-quote-buttons .home-button {
    padding: 10px 20px;
    font-size: 16px;
  }
  .single-quote-buttons .single-quote-contact-button {
    padding: 10px 20px;
    font-size: 16px;
  }
  .single-quote-buttons .single-quote-or {
    font-size: 25px;
  }

  .single-service-hero-bg {
    min-height: 850px;
    top: 20%;
  }
  .single-services-top {
    padding-top: 80px;
    padding-bottom: 50px;
  }

  .single-service-h1 {
    display: flex;
    /* justify-content: center; */
    text-align: center;
}
}

@media screen and (max-width: 400px) {
  .single-service-h1 {
    display: flex;
    /* justify-content: center; */
    text-align: center;
  }

  .single-services-header h1 {
    margin-bottom: 0px;
  }

  .single-service-content .single-services-title {
    font-size: 30px;
    margin-bottom: 20px;
  }
  .single-service-content p {
    font-size: 16px;
  }

  .single-service-content img {
    width: 70px;
    height: 70px;
  }

  .single-service-quote {
    width: 100%;
    border: 2px solid;
    padding: 35px 20px;
  }

  .single-service-quote .service-contact-icon {
    font-size: 30px;
    display: inline-block;
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .single-service-quote h2 {
    font-size: 28px;
  }

  .single-quote-buttons {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    align-items: normal;
    text-align: center;
  }

  .single-service-top-img {
    height: 300px;
    margin-bottom: 50px;
  }
  .service-body h2 {
    font-size: 28px;
  }
  .single-service-bottom-img {
    height: 250px;
  }

  .single-services-top {
    /* padding-top: 120px; */
    padding-bottom: 40px;
  }
}

@media screen and (max-width: 320px) {
  .single-services-header h1 {
    margin-bottom: 0px;
    font-size: 22px !important;
  }
  .single-services-header p {
    font-size: 14px !important;
    margin-left: 120px;
    border-left: 2px solid orange;
    margin-top: 2px;
    padding: 6px;
  }
}
