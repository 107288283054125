.our-methods-section {
  /* padding: 40px 20px; */
  border-radius: 30px;
  color: #333;
  margin-bottom: 110px;
}

.methods-top {
  /* background-color: aqua; */
  background-color: #0dcaf0;
  padding: 20px;
  border-radius: 20px 20px 0px 0px;
  position: relative;
  z-index: 0;
  border-bottom: 5px solid #0d6efd;
}

.methods-top h2 {
  font-size: 54px;
  font-weight: 700;
  line-height: 1.222em;
}

.methods-top .methods-top-subtitle {
  color: blue;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  margin-bottom: 8px;
  font-weight: 700;
  line-height: 1.111em;
  font-size: 20px;
}

.methods-top .subtitle-tag {
  /* color: #007bff; */
  color: #0056b3;
  /* text-decoration: underline; */
}

/* subnavber-buttons */
.methods-subnavbar-buttons {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  row-gap: 20px;
}

.method-button {
  padding: 16px 36px;
  border: 1px solid #000;
  background-color: #fff;
  box-shadow: 7px 7px 0 0 #000;
  transition: all 200ms ease;
  text-decoration: none;
  color: #0e0f18;
  font-size: 18px;
  line-height: 150%;
  font-weight: 500;
  /* margin-right: 20px; */
}

.method-button:hover {
  box-shadow: none;
}

.method-active {
  padding: 16px 36px;
  border: 1px solid #000;
  background-color: #0d6efd;
  box-shadow: 7px 7px 0 0 #007bff;
  transition: all 200ms ease;
  text-decoration: none;
  color: #f8f9fa;
  font-size: 18px;
  line-height: 150%;
  font-weight: 500;
}

/* methods-bottom */
.our-methods-section .methods-bottom {
  margin: 18px;
  min-height: 440px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #97cafe;
  color: #333;
  padding: 20px;
  padding-top: 30px;
  margin: 20px;
  margin-top: 0px;
  border-radius: 0px 0px 20px 20px;
  border-top: 2px solid black;
}

/* our methods - ourvalues */

.about-us-container ul {
  padding-left: 20px;
}

.about-us-container li {
  margin-bottom: 12px;
  font-size: 1.1rem;
  list-style: none;
  position: relative;
  padding-left: 25px;
}

.about-us-container li:before {
  content: "✓";
  color: #007bff;
  font-weight: bold;
  position: absolute;
  left: 0;
  top: 0;
}

/* ALL Our section */
.ourmethods h3 {
  color: #0056b3;
  text-decoration: underline #000;
  margin-bottom: 0px;
  font-size: 34px;
}
.ourmethods p {
  text-align: start;
  border-left: 2px solid;
  /* padding-left: 17px; */
  margin-left: 25px;
  padding: 20px;
  font-size: 18px;
}

/* our values secton */
.our-values-section ul {
  margin-top: 10px;
}
.our-values-section li {
  font-size: 18px;
}

/* Responsive-css */
@media (max-width: 991px) {
  .col-md-6 {
    width: 100%;
  }
  .ourmethods .method-imgs {
    height: 300px;
    order: 9999;
    text-align: center;
  }
  .method-imgs img {
    height: 100%;
    width: 90%;
    margin: auto;
  }
  .ourmethods h3 {
    font-size: 28px;
  }

  .ourmethods p {
    margin-left: 25px;
    padding: 10px;
    font-size: 18px;
  }
}

@media (max-width: 500px) {
  .method-active {
    padding: 10px 20px;
    font-size: 16px;
  }
  .method-button {
    padding: 10px 20px;
    font-size: 16px;
  }
  .about-us-container ul {
    padding-left: 0px;
  }
}

@media (max-width: 400px) {
  .ourmethods p {
    margin-top: 10px;
    margin-left: 0px;
    padding: 0px;
    border: none;
  }
  .our-methods-section .methods-bottom {
    padding: 10px;
    padding-top: 20px;
    margin: 5px;
    margin-top: 0px;
  }
  .ourmethods .method-imgs {
    height: 200px;
  }
  .ourmethods .method-imgs img {
    width: 95%;
  }
  .our-methods-section {
    margin-bottom: 70px;
  }
  .about-us-container li {
    font-size: 16px;
    padding-left: 17px;
  }
}
