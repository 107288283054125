#myTopBtn {
  display: none;
  position: fixed;
  bottom: 20px;
  right: 10px;
  z-index: 99;
  font-size: 18px;
  border: none;
  outline: none;
  background-color: rgb(245, 236, 236);
  color: #000;
  cursor: pointer;
  padding: 15px;
  border-radius: 35px;
  transition: all 400ms ease;
}

#myTopBtn:hover {
  background-color: #0d6efd;
  color: white;
}

@media screen and (max-width: 500px) {
  #myTopBtn {
    font-size: 16px;
    padding: 10px;
  }
}
