.contact-us-section {
  min-height: 100vh;
  /* padding-top: 200px; */
}

/* contact-us header banner */
.contact-us-header {
  min-height: 70vh;
  background-image: linear-gradient(
      to right,
      #032d5f 10%,
      rgba(1, 17, 36, 0.5) 47%,
      rgba(0, 0, 0, 0)
    ),
    url(/public/images/about/about-first.jpg);
  /* background-position: 0 0, 100% -10%; */
  background-repeat: repeat, repeat;
  background-size: auto, cover;
  /* margin-top: -81px; */
  padding-top: 160px;
  padding-bottom: 110px;
  position: relative;
  /* z-index: -1; */
  /* because of z-index:-1 subnav notworking */
}

.contact-us-header h1 {
  font-size: 2.8rem;
  margin-bottom: 10px;
  margin-left: 80px;
  border-bottom: 2px solid;
  display: inline;
}

.contact-us-header p {
  font-size: 1.1rem;
  color: #fdfdfd;
  /* line-height: 4; */
  margin-left: 120px;
  border-left: 2px solid orange;
  margin-top: 6px;
  padding: 10px;
}

/* header-subnav */
.contact-us-header .header-subnav {
  margin-left: 80px;
  font-size: 19px;
  margin-bottom: 10px;
}

.contact-us-header .header-subnav a {
  text-decoration: none;
  color: aliceblue;
}

.contact-us-header .header-subnav span {
  margin: 0px 3px;
}

/* hero- top-css */
.contact-us-top {
  padding-top: 200px;
  padding-bottom: 80px;
}

.contact-us-top-bg {
  /* background-color: #97cafe; */
  color: #333;
  /* border-radius: 0px 0px 0px 20px; */
  /* margin-bottom: 100px; */
}

.contactus-hero-bg {
  width: 100%;
  min-height: 750px;
  background-color: #97cafe;
  border-bottom-right-radius: 24px;
  /* border-bottom-left-radius: 24px; */
  top: 75%;
  bottom: 0%;
  left: auto;
  right: auto;
  transform: translate(0);
  position: absolute;
  z-index: -1;
}

.contact-us-section .contact-us-content {
  max-width: 700px;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.contact-us-content .contact-us-subtitle {
  /* color: blue; */
  color: #0056b3;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  margin-bottom: 8px;
  font-weight: 700;
  line-height: 1.111em;
  font-size: 20px;
}

.contact-us-content .contact-us-title {
  /* color: var(--neutral--800); */
  font-size: 60px;
  font-weight: 700;
  line-height: 1.111em;
  /* width: 800px; */
  margin-bottom: 30px;
}

.contact-us-title .subtitle-tag {
  color: #007bff;
  /* text-decoration: underline; */
}

.contact-us-content p {
  font-size: 18px;
  /* width: 680px; */
  font-size: 18px;
}

/* contact-section-email&phone */
.contact-cards-grid {
  grid-column-gap: 28px;
  grid-row-gap: 28px;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  align-items: center;
  display: grid;
  /* position: relative;
  z-index: 3;
  top: -160px; */
  padding-bottom: 120px;
}

.contact-cards-grid .contact-card {
  grid-row-gap: 24px;
  display: flex;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 50px 45px;
  background-color: #0dcaf0;
  color: #333;
  border-radius: 24px;
}

.contact-card .contact-icon {
  font-size: 50px;
  background-color: aqua;
  width: 90px;
  height: 90px;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.contact-card h2 {
  color: #0056b3;
  font-size: 28px;
  font-weight: 700;
  line-height: 1.429em;
  margin-bottom: 10px;
}

.contact-card p {
  margin-bottom: 40px;
  font-weight: 400;
  font-size: 18px;
}

.contact-card a {
  padding: 16px 36px;
  background-color: #fff;
  /* box-shadow: 7px 7px 0 0 #000; */
  box-shadow: inset 25px 7px 0 0 #000;
  transition: all 200ms ease;
  text-decoration: none;
  color: #0e0f18;
  font-size: 18px;
  line-height: 150%;
  font-weight: 500;
  border-radius: 0px 10px 10px 0px;
}

.contact-card a:hover {
  box-shadow: none;
  background-color: #007bff;
}

@media screen and (max-width: 991px) {
  .contact-us-content .contact-us-title {
    font-size: 50px;
  }
  .contact-us-content p {
    font-size: 18px;
  }

  .contact-cards-grid {
    grid-template-columns: 1fr;
    grid-row-gap: 40px;
  }

  .contactus-hero-bg {
    width: 100%;
    min-height: 1200px;    
  }
}

@media screen and (max-width: 550px) {
  .contact-us-content .contact-us-title {
    font-size: 40px;
  }
  .contact-us-content p {
    font-size: 17px;
  }

  .contact-form-top-content h2 {
    font-size: 34px;
  }

  .contact-cards-grid .contact-card {
    padding: 40px 20px;
  }

  .contact-us-top {
    padding-top: 80px;
    padding-bottom: 60px;
  }

  .contactus-hero-bg {
    width: 100%;
    min-height: 1100px;
    top: 20%;
  }

  .contact-card .contact-icon {
    font-size: 30px;
    background-color: aqua;
    width: 60px;
    height: 60px;
  }
  .contact-card h2 {
    color: #0056b3;
    font-size: 26px;
  }
  .contact-card p {
    margin-bottom: 30px;
    font-size: 17px;
  }
  .contact-card a {
    padding: 16px 20px;
    font-size: 16px;
    box-shadow: inset 12px 7px 0 0 #000;
  }
}

@media screen and (max-width: 400px) {
  .contact-us-content .contact-us-title {
    font-size: 30px;
    margin-bottom: 20px;
  }
  .contact-us-content p {
    font-size: 16px;
  }

  .contact-form-top-content h2 {
    font-size: 28px;
  }

  .contact-us-top {
    padding-top: 60px;
    padding-bottom: 40px;
  }

  .contact-cards-grid .contact-card {
    padding: 30px 10px;
  }

  .contact-card .contact-icon {
    font-size: 25px;
    background-color: aqua;
    width: 50px;
    height: 50px;
  }
  .contact-card h2 {
    color: #0056b3;
    font-size: 24px;
  }
  .contact-card p {
    margin-bottom: 30px;
    font-size: 16px;
  }
  .contact-card a {
    padding: 14px 10px;
    font-size: 16px;
    box-shadow: inset 8px 7px 0 0 #000;
  }

  .contact-cards-grid {
    padding-bottom: 80px;
  }

  .contactus-hero-bg {
    width: 100%;
    min-height: 800px;
  }
}

@media screen and (max-width: 320px) {
  .contact-card a {
    padding: 10px 5px;
    font-size: 12px;
    box-shadow: inset 3px 7px 0 0 #000;
  }
}
