.our-strength-section {
  background-color: #97cafe;
  border-radius: 24px;
  padding-top: 120px;
  padding-bottom: 90px;
  margin: 200px 0px;
  overflow: hidden;
  color: #333;
}

.our-strength-section .our-success {
  color:#0056b3 ;
  /* text-decoration: underline; */
}

.strength-content {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 30px;
}

/* left */
.grid-layout1 {
  display: grid;
  grid-row-gap: 15px;
  margin-bottom: 100px;
  /* margin-right: 30px; */
}

.grid-layout2 {
  display: grid;
  grid-row-gap: 15px;
  margin-top: 100px;
}

.grid-layout {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 30px;
  /* display: flex; */
  /* justify-content: space-evenly; */
}

.our-strength-section .stat-card {
  width: 100%;
  background-color: #343a40;
  color: #f8f9fa;
  border-radius: 24px;
  padding: 50px 10px;
  overflow: hidden;
  text-align: center;
}

.our-strength-section .stat-title {
  /* color: #333; */
  font-size: 72px;
  font-weight: 700;
  line-height: 1.111em;
}

/* .our-strength-section .stat-body {
  width: 13rem;
} */

.our-strength-section .stat-symbol {
  color: #0dcaf0;
}

/* right */

.strength-subtitle {
  color: blue;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  margin-bottom: 8px;
  font-weight: 700;
  line-height: 1.111em;
  font-size: 20px;
}

.strength-heading {
  font-size: 1.5rem;
  color: #333;
  margin-bottom: 20px;
  font-size: 52px;
  font-weight: 700;
  line-height: 1.222em;
}

.strength-description {
  font-size: 18px;
  line-height: 1.6;
  /* text-align: justify; */
}

.stat-buttons {
  display: flex;
  justify-content: space-evenly;
  margin-top: 30px;
  flex-wrap: wrap;
  row-gap: 20px;
}

.our-strength-section .stat-more-button {
  padding: 12px 26px;
  margin-left: 20px;
  border: 1px solid #000;
  background-color: #fff;
  box-shadow: 7px 7px 0 0 #0d6efd;
  transition: all 200ms ease;
  text-decoration: none;
  color: #0e0f18;
  font-size: 18px;
  line-height: 150%;
  font-weight: 500;
}

.our-strength-section .stat-more-button:hover {
  box-shadow: none;
}

/* Responsive css-medium */
@media screen and (max-width: 991px) {
  .strength-content {
    grid-row-gap: 50px;
    grid-template-columns: 1fr;
  }
  .strength-content .left-content {
    order: 9999;
  }
  .strength-heading {
    font-size: 48px;
  }
  .strength-description {
    font-size: 17px;
  }
  .our-strength-section {
    padding-top: 90px;
    padding-bottom: 90px;
  }
}

@media screen and (max-width: 550px) {
  .our-strength-section .stat-title {
    font-size: 50px;
  }
  .our-strength-section .stat-card {
    padding: 40px 10px;
  }
}

@media screen and (max-width: 400px) {
  .our-strength-section {
    padding-top: 70px;
    padding-bottom: 60px;
    margin: 100px 0px;
  }
  .our-strength-section .stat-title {
    font-size: 30px;
  }
  .our-strength-section .stat-card {
    padding: 20px 10px;
  }
  .our-strength-section .grid-layout1 {
    margin-bottom: 40px;
  }
  .our-strength-section .grid-layout2 {
    margin-top: 40px;
  }
  .our-strength-section .stat-more-button {
    padding: 10px 20px;
    font-size: 16px;
  }
}
