.about-company-story-section {
  /* background-color: #97cafe; */
  padding: 40px 20px;
  /* background: url(/public/images/vsm-logo.jpeg) no-repeat center; */
  /* background-position: 0 0, 100% -10%; */
  /* background-size: cover; */
  /* position: relative; */
  z-index: -1;
  border-radius: 0px 0px 20px 20px;
}

.about-table {
  background-color: #97cafe;
  border-radius: 0px 0px 20px 20px;
  padding-top: 100px;
  position: relative;
}

.about-table .our-company {
  /* color: #007bff; */
  color: #0056b3;
  /* text-decoration: underline; */
}

.about-company-story-section .hero-card {
  z-index: 0;
  /* top: 155%; */
  left: 80px;
  bottom: 64px;
  width: 280px;
  height: 210px;
  border-radius: 0px 0px 0px 15px;
  background-color: aquamarine;
  position: absolute;
}

.about-company-story-section .row {
  z-index: 2;
  position: relative;
}

.about-company-story-section .first-row{
  margin-bottom: 55px !important;
}

.about-company-story-section h1 {
  font-family: "Roboto", sans-serif;
  color: #333;
  margin-bottom: 20px;
  font-size: 72px;
  font-weight: 700;
  line-height: 1.111em;
}

.about-company-story-section p {
  font-size: 1.1rem;
  /* color: #555; */
  color: #333;
  margin-bottom: 20px;
  font-size: 18px;
  font-weight: 400;
  line-height: 1.667em;
  padding-bottom: 15px;
}

.about-company-story-section .btn-primary {
  background-color: #007bff;
  border-color: #007bff;
  transition: background-color 0.3s, border-color 0.3s;
  font-size: 24px;
}

.about-company-story-section .btn-primary:hover {
  background-color: #0056b3;
  border-color: #0056b3;
}

.about-company-story-section .btn-secondary {
  background-color: #6c757d;
  border-color: #6c757d;
  transition: background-color 0.3s, border-color 0.3s;
}

.about-company-story-section .btn-secondary:hover {
  background-color: #5a6268;
  border-color: #545b62;
}

.about-company-story-section .img-fluid {
  width: 100%;
  border-radius: 25px;
  vertical-align: middle;
}

.about-company-story-section .aboutus-more-button {
  padding: 12px 26px;
  margin-left: 20px;
  border: 1px solid #000;
  background-color: #fff;
  box-shadow: 7px 7px 0 0 #0d6efd;
  transition: all 200ms ease;
  text-decoration: none;
  color: #0e0f18;
  font-size: 18px;
  line-height: 150%;
  font-weight: 500;
}

.about-company-story-section .aboutus-more-button:hover {
  box-shadow: none;
}

.aboutus-more-button .down-arrow {
  font-size: 20px;
  font-weight: bold;
}

/* Responsive css-medium */
@media screen and (max-width: 991px) {
  .about-table {
    padding-top: 60px;
  }
  .about-company-story-section .story-img {
    order: 9999;
  }
  .about-company-story-section h1 {
    font-size: 50px;
  }

  .about-company-story-section p {
    font-size: 18px;
  }

  .about-company-story-section .row {
    row-gap: 40px;
  }

  .about-company-story-section .col-md-5 {
    width: 100%;
  }

  .about-company-story-section .col-md-7 {
    width: 90%;
    height: 300px;
    margin: auto;
  }
  .about-company-story-section .img-fluid {
    height: 100%;
  }
}

@media screen and (max-width: 768px) {
  .about-company-story-section h1 {
    font-size: 40px;
  }

  .about-company-story-section p {
    font-size: 17px;
  }
  .about-company-story-section .hero-card {
    display: none;
  }
}

@media screen and (max-width: 400px) {
  .about-table {
    padding-top: 30px;
  }
  .about-company-story-section h1 {
    font-size: 32px;
  }

  .about-company-story-section p {
    font-size: 17px;
  }
  .about-company-story-section .hero-card {
    display: none;
  }
  .about-company-story-section .col-md-7 {
    width: 90%;
    height: 200px;
    margin: auto;
  }
  .about-company-story-section .home-button {
    padding: 10px 20px;
    font-size: 16px;
  }
  .about-company-story-section .aboutus-more-button {
    padding: 10px 20px;
    font-size: 16px;
  }
}
