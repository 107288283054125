/* @import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&display=swap');

.navbar {
  padding: 0px;
  background-color: #fff !important;
  /* font-family: "DM Serif Display", serif !important; */
  /* font-family: "Yeseva One", serif !important; */
  /* font-family: "Open Sans", sans-serif; */
  font-family: "Merriweather", serif;
  font-style: normal;
  font-weight: 500 !important;
}

.navbar .container-fluid {
  border-top: 6px solid #fff;
  color: #000;
  padding: 0px;
}

.navbar .nav-item .nav-link {
  font-size: 20px;
  font-weight: 700;
  margin: 0px 10px;
  padding: 17px 10px !important;
  border-bottom: 6px solid;
  border-color: #0dcaf0;
  transition: all 500ms ease-in-out;
}

.navbar .navbar-toggler {
  margin-right: 20px;
}

.nav-active {
  background-color: #fff;
  color: #0a58ca;
  border-bottom: 6px solid orange !important;
  transition: all 200ms ease;
}

.navbar .nav-link:hover {
  background-color: #fff;
  color: #0a58ca;
  border-bottom: 6px solid orange;
}

.dropdown-menu {
  width: 230px;
  transition: all 200ms ease;
}

.dropdown-item:hover {
  transition: all 200ms ease;
  background-color: #97cafe;
  color: #000;
}

.dropdown-active {
  background-color: #0bf;
  color: #080e24;
  transition: all 200ms ease;
  text-decoration: none;
  padding: 6px;
  /* text-align: center; */
  width: 100%;
  display: block;
  padding-left: 16px;
}

.navbar .navbar-brand {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 25px;
  font-weight: 700;
  padding: 4px;
  padding-right: 0px;
  /* font-family: "DM Serif Display", serif !important; */
}

.navbar .logo-name {
  margin-left: 10px;
  padding-right: 20px;
  margin-left: 10px;
  padding-right: 20px;
  color: #0d6efd;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.logo-name .name-hyd {
  color: orange;
  font-size: 14px;
}

.navbar .navbar-brand .logo {
  width: 120px;
  height: 50px;
  margin-left: 20px;
  /* mix-blend-mode: multiply; */
}

.navbar .offcanvas {
  /* background-color: #0dcaf029; */
  background-color: #0dcaf0 !important;
  padding-right: 30px;
  width: 330px !important;
}

/* to fix a navbar-offcanvas bug */
/* @media ((min-width: 991px) and (max-width: 992px)) {
  .offcanvas {
    display: none;
  }
} */

@media (max-width: 991px) {
  .offcanvas-body .navbar-nav {
    min-height: 80vh;
  }
  .offcanvas-body .justify-content-end {
    justify-content: normal !important;
  }

  .navbar .container-fluid {
    border-top: 0px;
  }

  .offcanvas {
    visibility: hidden;
    /* opacity: 0; */
  }
  .navbar-expand-lg .offcanvas-body {
    display: flex !important;
    padding: 0 !important;
  }
  .navbar-expand-lg .offcanvas-body ul {
    /* flex-direction: row; */
    margin: 0;
  }
  .navbar-expand-lg .offcanvas-body ul li {
    margin-left: 20px;
  }

  .dropdown-menu li {
    margin-left: 0px !important;
  }
  .navbar .offcanvas {
    padding-right: 10px;
  }
}

@media (max-width: 400px) {
  .navbar .offcanvas {
    width: 270px !important;
  }
}

@media (max-width: 1100px) {
  .navbar .nav-link {
    margin: 0px !important;
  }
}

@media (max-width: 991px) {
  .nav-active {
    background-color: #0dcaf0;
    color: #0a58ca;
    /* border-bottom: 0px !important; */
    display: inline-block;
    border-bottom: 6px solid #ff8900b5 !important;
  }
  .navbar .nav-link{
    display: inline-block;
  }

  .navbar .nav-link:hover {
    background-color: #0dcaf0;
    color: #0a58ca;
    /* border-bottom: 0px !important; */
  }
  /* .navbar .nav-item .nav-link {
    border-bottom: 0px !important;
  } */
  .navbar-toggler {
    margin-right: 20px;
  }
}

@media (max-width: 500px) {
  .navbar .navbar-brand .logo {
    margin: 0px !important;
    width: 90px;
  }
  .navbar .logo-name {
    font-size: 17px;
    margin: 0px;
    padding-right: 0px;
  }
}

@media (max-width: 340px) {
  .navbar .navbar-brand {
    flex-direction: column;
  }
  .navbar .navbar-brand .logo {
    height: 40px;
  }
}
