.select-dropdown {
  position: relative;
}

.selected-item {
  /* width: 300px; */
  /* border: 1px solid; */
  background-color: #f3f6ff;
  color: black;
  padding: 20px 20px;
  font-size: 18px;
  cursor: pointer;
  border-radius: 14px;
}

.select-options {
  background-color: #cdd9e5;
  color: black;
  /* border-radius: 0px 0px 10px 10px; */
  position: absolute;
  left: 10px;
  z-index: 99;
  display: none;
  padding-left: 0px;
}

.select-options li {
  list-style: none;
  padding: 8px;
  cursor: pointer;
  /* width: 220px; */
}

.select-dropdown .down-arrowhead{
    color: black;
    position: absolute;
    right: 20px;
    top: 15px;
    font-size: 18px;
    font-weight: bold;
    cursor: pointer;
}

.select-options li:hover {
  background-color: #00bcd4;
}

.selected-active {
  background-color: #00bcd4;
}

@media screen and (max-width: 550px) {
  .selected-item {
    min-height: 60px;
    border-radius: 10px;
    font-size: 16px;
  }
}

@media screen and (max-width: 400px) {
  .contact-form-card .servicetype {
    font-size: 17px;
  }

  .selected-item {
    min-height: 50px;
    padding: 10px 18px;
  }

  .select-dropdown .down-arrowhead {
    top: 5px;
  }
}

@media screen and (max-width: 300px) {
  .select-options li {
    padding: 6px;
    font-size: 14px;
  }
}
