.we-are-best-at-section {
  padding: 80px 0;
  /* background-color: #f3f4f6; */
  text-align: center;
  color: whitesmoke;
}

.we-are-best-at-section {
  background: url(/public/images/about/about-bg.jpg) no-repeat center fixed;
  background-size: cover;
  position: relative;
  z-index: 0;
}

.we-are-best-at-section::after {
  content: "";
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  z-index: -1;
  background: rgb(0 0 0 / 45%);
}

.we-are-best-at-section .section-title {
  font-size: 2.8rem;
  font-weight: bold;
  margin-bottom: 10px;
  /* text-decoration: underline #007bff; */
  color: #0bf;
}

.we-are-best-at-section .section-subtitle {
  font-size: 1.3rem;
  color: #fff;
  margin-bottom: 50px;
}

.we-are-best-at-section .best-card {
  background-color: #ffffff;
  padding: 30px;
  margin-bottom: 30px;
  border-radius: 15px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
  transition: transform 0.3s, box-shadow 0.3s;
  cursor: pointer;
}

.best-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 12px 25px rgba(0, 0, 0, 0.2);
}

.we-are-best-at-section .icon {
  color: #ff7e5f;
  margin-bottom: 15px;
}

.we-are-best-at-section .card-title {
  color: #0d6efd;
  font-size: 1.8rem;
  margin-bottom: 15px;
  font-weight: bold;
}

.we-are-best-at-section .card-text {
  font-size: 1rem;
  color: #555;
}

@media (max-width: 768px) {
  .we-are-best-at-section .section-title {
    font-size: 2.5rem;
  }

  .we-are-best-at-section .section-subtitle {
    font-size: 1.2rem;
  }

  .we-are-best-at-section .best-card {
    padding: 20px;
  }

  .we-are-best-at-section .card-title {
    font-size: 1.5rem;
  }

  .we-are-best-at-section .card-text {
    font-size: 0.95rem;
  }
}

@media (max-width: 400px) {
  .we-are-best-at-section {
    padding: 61px 0;
}
  .we-are-best-at-section .section-title {
    font-size: 28px;
  }
  .we-are-best-at-section .section-subtitle {
    font-size: 16px;
    margin-bottom: 30px;
}
}