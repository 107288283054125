.home-hero-section {
  min-height: 100vh;
  margin-bottom: 100px;
  padding-top: 80px;
  background-color: #97cafe;
  color: #333;
  border-radius: 0px 0px 0px 30px;
}

.home-grid-layout {
  display: grid;
  grid-column-gap: 65px;
  grid-template-columns: 1.15fr 1fr;
  align-items: start;
  grid-row-gap: 28px;
  grid-template-rows: auto;
}

/* left */
.hero-content {
  /* margin: 100px 0px; */
  margin-top: 130px;
}

.hero-content .hero-heading {
  font-size: 60px;
  font-weight: 700;
  line-height: 1.111em;
  margin-bottom: 30px;
}

.hero-heading .hero-quotes {
  color: #0056b3;
  /* text-decoration: underline; */
}

.hero-content .hero-para {
  font-size: 20px;
  margin-bottom: 20px;
}

/* right */
.home-hero-section .hero-img {
  height: 600px;
}

.hero-img img {
  width: 100%;
  height: 100%;
  border-radius: 20px;
  vertical-align: middle;
  position: relative;
  top: 50px;
}

/* home-button */
.hero-buttons {
  display: flex;
  justify-content: space-evenly;
  margin-top: 22px;
  flex-wrap: wrap;
}


/* Responsive css-medium */
@media screen and (max-width: 991px) {
  .home-grid-layout {
    height: auto;
    /* grid-row-gap: 60px; */
    grid-template-columns: 1fr;
  }

  .home-grid-layout .hero-content {
    text-align: center;
    margin-top: 50px;
  }

  .home-hero-section .hero-heading {
    font-size: 54px;
  }
  .home-hero-section .hero-para {
    font-size: 17px;
  }

  .home-grid-layout .hero-img img {
    top: 90px;
  }
}

/* Responsive css-small */
@media screen and (max-width: 550px) {
  .home-hero-section {
    padding-top: 10px;
  }

  .home-hero-section .hero-heading {
    font-size: 34px;
  }
  .home-hero-section .hero-para {
    font-size: 17px;
  }

  .home-hero-section .hero-img {
    height: 400px;
    margin-bottom: 30px;
  }

  .home-hero-section .home-button {
    margin-right: 20px;
  }

  .home-grid-layout .hero-img img {
    top: 0px;
  }
}
@media screen and (max-width: 400px) {
  .home-hero-section .home-button {
    margin-bottom: 20px;
  }

  .hero-buttons .btn-outline-primary{
    padding: 10px 20px !important;
    font-size: 16px !important;
    height: 50px !important;
  }
}

