* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

body {
  color: aliceblue;
  background-color: rgb(31, 27, 27);
  /* overflow-x: hidden; */
}

.layout {
  min-height: 100vh;
}

/* fonts pairing */
h1,
h2 {
  font-family: "DM Serif Display", serif !important;
  font-style: normal;
  font-weight: 500 !important;
}

p {
  font-family: "Poppins", sans-serif !important;
  font-style: normal;
}

/* .subtitle{
  font-family: "Yeseva One", serif !important;
} */

header {
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  /* padding-top: 0px !important;
  padding-bottom: 0px !important; */
  /* min-height: 80vh !important; */
}

/* responsive css for all pages header */
@media screen and (max-width: 726px) {
  /* header{
    position: fixed !important;
  } */
  header .header-subnav {
    margin-left: 45px !important;
    font-size: 16px !important;
  }
  header h1 {
    margin-left: 45px !important;
    font-size: 34px !important;
  }
  header p {
    margin-left: 70px !important;
    font-size: 17px !important;
  }
}
@media screen and (max-width: 400px) {
  header .header-subnav {
    margin-left: 20px !important;
    font-size: 16px !important;
  }
  header h1 {
    margin-left: 20px !important;
    font-size: 30px !important;
  }
  header p {
    margin-left: 35px !important;
    font-size: 17px !important;
  }
}

@media screen and (max-width: 550px) {
  header {
    min-height: 50vh !important;
    padding-top: 60px !important;
    padding-bottom: 40px !important;
  }
}
