.get-in-touch-preview {
  padding: 80px 0;
  background: linear-gradient(135deg, #0dcaf0, #feb47b);
  color: #333;
}

.get-in-touch-preview .text-section {
  text-align: left;
}

.get-in-touch-preview .row{
    row-gap: 10px;
}

.get-in-touch-preview .preview-title {
  font-size: 3rem;
  font-weight: bold;
  margin-bottom: 20px;
}

.get-in-touch-subtitle{
  color: blue;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  margin-bottom: 8px;
  font-weight: 700;
  line-height: 1.111em;
  font-size: 20px;
}

.get-in-touch-preview .preview-text {
  font-size: 1.2rem;
  line-height: 1.6;
  margin-bottom: 30px;
}

.get-in-touch-preview .btn-custom {
  background-color: #0d6efd;
  color: #212529;
  padding: 15px 30px;
  border-radius: 50px;
  font-weight: bold;
  font-size: 1.1rem;
  transition: all 0.3s ease-in-out;
  text-transform: uppercase;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
}

.get-in-touch-preview .btn-custom:hover {
  background-color: #ff7e5f;
  color: #ffffff;
}

.get-in-touch-preview .image-section {
  text-align: center;
}

.get-in-touch-preview .contact-image {
  border-radius: 15px;
  /* box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2); */
}

@media (max-width: 768px) {
  .get-in-touch-preview .preview-title {
    font-size: 2.5rem;
  }

  .get-in-touch-preview .preview-text {
    font-size: 1rem;
  }

  .get-in-touch-preview .text-section {
    text-align: center;
    margin-bottom: 40px;
  }
}

@media (max-width: 400px) {
  .get-in-touch-preview {
    padding-top: 60px;
    padding-bottom: 40px;
  }
  .get-in-touch-preview .preview-title {
    font-size: 30px;
    margin-bottom: 10px;
  }
  .get-in-touch-preview .preview-text {
    font-size: 16px;
  }
  .get-in-touch-preview .btn-custom {
    padding: 10px 20px;
    font-size: 16px;
  }
}