.main-services-section {
  min-height: 100vh;
  /* padding-top: 200px; */
}

/* MAin service header banner */
.main-services-header {
  min-height: 70vh;
  background-image: linear-gradient(
      to right,
      #032d5f 10%,
      rgba(1, 17, 36, 0.5) 47%,
      rgba(0, 0, 0, 0)
    ),
    url(/public/images/about/about-first.jpg);
  background-repeat: repeat, repeat;
  background-size: auto, cover;
  padding-top: 160px;
  padding-bottom: 110px;
  position: relative;
  /* z-index: -1; */
}

.main-services-header h1 {
  font-size: 2.8rem;
  margin-bottom: 10px;
  margin-left: 80px;
  border-bottom: 2px solid;
  display: inline;
}

.main-services-header p {
  font-size: 1.1rem;
  color: #fdfdfd;
  /* line-height: 4; */
  margin-left: 120px;
  border-left: 2px solid orange;
  margin-top: 6px;
  padding: 10px;
}

/* header-subnav */
.main-services-header .header-subnav {
  margin-left: 80px;
  font-size: 19px;
  margin-bottom: 10px;
}

.main-services-header .header-subnav a {
  text-decoration: none;
  color: aliceblue;
}

.main-services-header .header-subnav span {
  margin: 0px 3px;
}

/* hero- top-css */
.main-services-top {
  padding-top: 200px;
  padding-bottom: 100px;
}

.main-services-top-bg {
  background-color: #97cafe;
  color: #333;
  border-radius: 0px 0px 20px 20px;
  margin-bottom: 100px;
}

.main-services-section .main-service-content {
  max-width: 800px;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.main-service-content .main-services-subtitle {
  /* color: blue; */
  color: #0056b3;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  margin-bottom: 8px;
  font-weight: 700;
  line-height: 1.111em;
  font-size: 20px;
}

.main-service-content .main-services-title {
  /* color: var(--neutral--800); */
  font-size: 60px;
  font-weight: 700;
  line-height: 1.111em;
  /* width: 800px; */
  margin-bottom: 30px;
}

.main-services-title .subtitle-tag {
  color: #007bff;
  /* text-decoration: underline; */
}

.main-service-content p {
  /* width: 850px; */
  font-size: 18px;
}

.services-triangle-down {
  background-color: aqua;
  border-bottom: 30px solid aqua;
  border-left: 25px solid;
  border-right: 25px solid;
  border-top: none;
  font-size: 20px;
  padding: 10px;
  margin-top: 20px;
}

/* bottom css */
.main-services-bottom {
  padding: 50px 0px;
  margin-bottom: 60px;
}

/* main-services-cards css */
.main-grid-layout {
  grid-column-gap: 50px;
  grid-row-gap: 90px;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  align-items: center;
  display: grid;
}

.service-card-wrapper {
  text-decoration: none;
  color: #333;
}

.servide-img-wrapper {
  width: 90%;
  margin-left: 10%;
}

.service-card-img {
  width: 100%;
  height: 350px;
  display: flex;
  justify-content: end;
  overflow: hidden;
  border-radius: 20px;
}

.service-card-img img {
  width: 100%;
  height: 100%;
  border-radius: 20px;
  transition: transform 0.3s ease;
}

/* card-animation */
.service-card-wrapper:hover .service-card-img img {
  transform: scale(1.2);
}

.service-card-wrapper:hover .service-inner-content h3 {
  color: #0056b3;
}

.service-card-icon img {
  width: 60px;
  height: 60px;
  object-fit: cover;
  border-radius: 65px;
}

.main-service-card {
  position: relative;
  padding-bottom: 180px;
}

.main-service-card-content {
  width: 90%;
  min-height: 250px;
  display: flex;
  justify-content: space-evenly;
  border: 2px solid;
  text-decoration: none;
  align-items: center;
  padding: 20px;
  border-radius: 20px;
  background-color: #97cafe;
  /* position: relative;
  z-index: 2;
  top: -80px; */
  position: absolute;
  z-index: 2;
  top: 270px;
}

.service-inner-content {
  padding-left: 30px;
}

.service-inner-content h3 {
  font-size: 27px;
  transition: transform 0.3s ease;
}

.main-service-button {
  padding: 10px 20px;
  border: 1px solid #000;
  background-color: #fff;
  box-shadow: 7px 7px 0 0 #000;
  transition: all 200ms ease;
  text-decoration: none;
  color: #0e0f18;
  font-size: 16px;
  line-height: 150%;
  font-weight: 500;
  border-radius: 10px;
}

.service-card-wrapper:hover .main-service-button {
  color: #0056b3;
  box-shadow: none;
}

@media screen and (max-width: 991px) {
  .main-service-content .main-services-title {
    font-size: 50px;
  }

  .main-grid-layout {
    grid-template-columns: 1fr;
    grid-row-gap: 60px;
  }
}

@media screen and (max-width: 550px) {
  .main-service-content .main-services-title {
    font-size: 40px;
  }
  .main-service-content p {
    font-size: 17px;
  }
  .main-services-top {
    padding-top: 120px;
    padding-bottom: 80px;
  }
  .services-triangle-down {
    background-color: aqua;
    border-bottom: 20px solid aqua;
    border-left: 15px solid;
    border-right: 15px solid;
    border-top: none;
    font-size: 17px;
    padding: 10px;
    margin-top: 20px;
  }

  .service-card-img {
    width: 100%;
    height: 270px;
  }
  .main-service-card-content {
    top: 200px;
    padding: 12px;
  }
  .service-inner-content {
    padding-left: 10px;
  }
}

@media screen and (max-width: 400px) {
  .main-services-top {
    padding-top: 110px;
    padding-bottom: 50px;
}
  .main-service-content .main-services-title {
    font-size: 30px;
  }
  .main-service-content p {
    font-size: 16px;
  }
  .services-triangle-down {
    background-color: aqua;
    border-bottom: 10px solid aqua;
    border-left: 15px solid;
    border-right: 15px solid;
    border-top: none;
    font-size: 16px;
    padding: 10px;
    margin-top: 20px;
  }

  .service-inner-content h3 {
    font-size: 22px;
    transition: transform 0.3s ease;
  }

  .main-service-card {
    position: relative;
    padding-bottom: 230px;
  }
  .main-service-card-content {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    border: none;
    text-decoration: none;
    align-items: center;
    padding: 6px;
    padding-bottom: 14px;
    background-color: #97cafe;
    position: static;
    flex-wrap: wrap;
    border-radius: 0px 0px 20px 20px;
  }

  .servide-img-wrapper {
    width: 100%;
    margin-left: 0px;
  }
  .service-card-img {
    border-radius: 20px 20px 0px 0px;
    width: 100%;
    height: 230px;
  }
  .service-card-img img {
    border-radius: 20px 20px 0px 0px;
  }
  .main-service-card {
    padding-bottom: 40px;
  }
}
