/* top-css */
.our-clients-section {
  padding: 40px 20px;
  border-radius: 30px;
  /* color: #333; */
  margin-bottom: 110px;
  margin-top: 50px;
}

.our-clients-top {
  margin-bottom: 25px;
  margin-left: 25px;
}

.our-clients-section h2 {
  font-size: 54px;
  font-weight: 700;
  line-height: 1.222em;
}

.our-clients-section .our-clients-top-subtitle {
  color: blue;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  margin-bottom: 8px;
  font-weight: 700;
  line-height: 1.111em;
  font-size: 20px;
}

.our-clients-top .subtitle-tag {
  color: #007bff;
  /* text-decoration: underline; */
}

.our-clients-section .our-clients-tag-button {
  display: flex;
  justify-content: center;
  /* align-items: center; */
}

.our-clients-tag-button .drag-button {
  width: 100px;
  height: 100px;
  border-radius: 53px;
  font-size: 30px;
  background-color: azure;
  color: #333;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.our-clients-tag-button .drag-button .drag-content {
  cursor: grab;
  color: black;
  background-color: rgb(0, 187, 255);
  font-weight: 600;
  /* padding: 10px;  */
  font-size: 21px;
  border-radius: 56px;
  height: 70px;
  width: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* bottom-css */
.carousel-container {
  overflow: hidden;
  /* min-height: 100vh; */
  mask-image: linear-gradient(
    to right,
    hsl(0 0% 0% / 0),
    hsl(0 0% 0% / 1) 10%,
    hsl(0 0% 0% / 1) 90%,
    hsl(0 0% 0% / 0)
  );
}

.splide__slide {
  /* min-width: 80px; */
  height: 100px;
  margin: 0 10px;
  padding: 10px;
  /* background-color: #fff; */
  /* border: 1px solid #ddd; */
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  text-align: center;
  font-size: 16px;
  color: #333;
  cursor: grab;
}

.slide-item{
  width: 100%;
  height: 100%;
}

.slide-item img{
  width: 100%;
  height: 100%;
  object-fit: fill;
  border-radius: 10px;
}

/* Responsive css-medium */
@media screen and (max-width: 991px) {
  .our-clients-section {
    padding: 40px 0px;
    margin-bottom: 50px;
  }

  .our-clients-section h2 {
    font-size: 48px;
  }

  .our-clients-section .our-clients-tag-button {
    display: none;
  }
  .our-clients-section .our-clients-top {
    display: block;
    margin-left: 0px;
    margin-bottom: 40px;
  }
  .our-clients-top .our-clients-top-content {
    width: 100%;
    /* text-align: center; */
  }
}

@media screen and (max-width: 550px) {
  .our-clients-section h2 {
    font-size: 34px;
  }
  .our-clients-top .our-clients-top-content {
    padding-left: 30px;
  }
}
@media screen and (max-width: 400px) {
  .our-clients-section h2 {
    font-size: 28px;
  }
  .our-clients-top .our-clients-top-content {
    padding-left: 15px;
  }
  .carousel-container {
    overflow: hidden;
    /* min-height: 100vh; */
    mask-image: linear-gradient(
      to right,
      hsl(0 0% 0% / 0),
      hsl(0 0% 0% / 1) 8%,
      hsl(0 0% 0% / 1) 96%,
      hsl(0 0% 0% / 0)
    );
  }
}
