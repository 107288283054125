.founder-section {
  padding: 60px 0;
  /* background-color: #f8f9fa; */
  /* border: 1px solid; */
  padding-bottom: 80px;
}

.founder-grid-body {
  row-gap: 20px;
  display: grid;
  grid-template-columns: 1.2fr 1fr;
}

.founder-section .text-content {
  padding: 20px;
}

.founder-section .subtitle-tag {
    color: #007bff;
    /* text-decoration: underline; */
}

.founder-section .subtitle {
  color: blue;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  margin-bottom: 8px;
  font-weight: 700;
  line-height: 1.111em;
  font-size: 20px;
}

.founder-section .quote {
  font-size: 54px;
  font-weight: 700;
  line-height: 1.222em;
  margin-bottom: 25px;
}

.founder-section .description {
  font-size: 18px;
  /* color: #6c757d; */
  line-height: 1.6;
}

.founder-section .image-content {
  position: relative;
  text-align: center;
}

.img-body {
  height: 400px;
  width: 320px;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.2);
  margin: auto;
}

.founder-section .founder-image {
  width: 100%;
  height: 83%;
  position: relative;
  z-index: 2;
  border-left: 20px solid #007bff;
}

.founder-section .founder-roles {
  display: flex;
  flex-direction: column;
  background-color: #007bff;
  padding: 10px;
  border-bottom-left-radius: 20px;
}

.founder-section .subimg-truck {
  height: 300px;
  width: 300px;
  position: absolute;
  z-index: -1;
  top: 136px;
  left: -140px;
}

.subimg-truck img {
  width: 100%;
  height: 100%;
}

/* Responsive css-medium */
@media screen and (max-width: 991px) {
  .founder-grid-body {
    grid-template-columns: 1fr;
  }

  .founder-section .quote {
    font-size: 48px;
  }

  .founder-section .description {
    font-size: 17px;
  }

  .founder-section .subimg-truck {
    left: 0;
  }
}

@media screen and (max-width: 550px) {
  .founder-section .quote {
    font-size: 34px;
  }
  .founder-section .subimg-truck {
    display: none;
  }
}

@media screen and (max-width: 400px) {
  .founder-section .quote {
    font-size: 28px;
  }
  .founder-section .description {
    font-size: 16px;
  }
  .founder-section .text-content {
    padding: 10px;
  }

  .img-body {
    padding: 20px;
    height: 350px;
    width: auto;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.2);
    margin: auto;
  }
  .founder-section .quote {
    margin-bottom: 15px;
  }
}
